import React from "react";

import LayoutTemplate from "../components/templates/layout";
import SeoElement from "../components/elements/seo";
import NotFoundModule from "../components/modules/not-found";

export default () => {
	const seoData = {
		title: "404 Page Not Found",
		path: "/404/"
	};

	return (
		<>
			<SeoElement page={seoData} />
			<LayoutTemplate>
				<NotFoundModule />
			</LayoutTemplate>
		</>
	);
};
