import React from "react";
import { Link } from "gatsby";

import "./index.scss";

export default () => {
	return (
		<div className="not-found">
			<h3>Page Not Found (404)</h3>
			<Link to="/">Back to Home</Link>
		</div>
	);
};
